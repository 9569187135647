import Rest, { Model } from './Rest';

export interface GalleryModel extends Model{
    id?: string,
    name?: string,
    slug?: string,
}

class GalleryService extends Rest {
    resourceable_as: string = 'App\\Models\\Gallery';
}

export default new GalleryService('GalleryService', '/galleries');
