import React from 'react';
import { PageHeader, Button, Modal, Input } from 'antd';
import useList from '../../hooks/useList';
import TestimonyService from '../../services/TestimonyService';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import Table from '../../Components/Table';
import Route from '../../helpers/Route';

function TestimoniesList(props) {
    const {
        collection,
        loading,
        pagination,
        deletingItem,
        queryState,
        onTableChange,
        onSearch,
        asTokDeleteResource,
        confirmDeleteResource,
        closeModal,
    } = useList(TestimonyService, props.querystring);

    let deleteModal: null | JSX.Element = null;
    if (deletingItem !== false) {
        deleteModal = (
            <Modal visible confirmLoading={ loading }
                okText="Excluir"
                cancelText="Cancelar"
                cancelButtonProps={ {disabled: loading} }
                title={ (
                    <span>
                        <ExclamationCircleOutlined className="text-orange" /> Atenção
                    </span>
                ) }
                onOk={ confirmDeleteResource }
                onCancel={ closeModal }>
                Deseja remover o depoimento <strong>{deletingItem.name}</strong>?
            </Modal>
        );
    }

    const columns = [
        {title: 'ID', dataIndex: 'id', sorter: true, align: 'center', width: 50},
        {title: 'Nome', dataIndex: 'name', sorter: true},
        {title: 'Depoimento', dataIndex: 'content', sorter: true},
        {title: 'Depoimento (Inglês)', dataIndex: 'content_en', sorter: true},
        {title: 'Alterar', dataIndex: '', align: 'center', render: (testimony) => {
            return <Button href={ Route('testimoniesEdit', {id: testimony.id}) } type="primary" shape="circle" icon={ <EditOutlined /> } />
        } },
        {title: 'Excluir', dataIndex: '', align: 'center', render: (testimony) => {
            return <Button onClick={ asTokDeleteResource.bind(null, testimony) } type="ghost" shape="circle" icon={ <DeleteOutlined /> } />
        } },
    ]

    return (
        <div>
            <PageHeader className="p-0 mb-4"
                title="Depoimentos"
                extra={ <Button href={ Route('testimoniesCreate') } type="primary">Novo depoimento</Button> }
            />
            <Input.Search className="list-input-search" value={ queryState.$q || '' } onChange={ onSearch } />
            <Table
                columns={ columns }
                rowKey={ testimony => testimony.id }
                collection={ collection }
                pagination={ pagination }
                loading={ loading }
                onChange={ onTableChange }
            />
            { deleteModal }
        </div>
    );
}

export default TestimoniesList;
