import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

export default class Router {
    defaultLayout: null | React.ReactNode = null;
    RootNode;
    constructor(node) {
        this.RootNode = node;
    }

    renderPage(PageComponent, Layout: any = this.defaultLayout) {
        return (context) => {
            const querystring = qs.parse(context.path.split('?')[1]);
            if (Layout) {
                ReactDOM.render(
                    <ConfigProvider locale={ ptBR }>
                        <Layout pageContext={ context } params={ context.params }>
                            <PageComponent pageContext={ context } params={ context.params } querystring={ querystring } />
                        </Layout>
                    </ConfigProvider>,
                    this.RootNode
                );
                return;
            }

            ReactDOM.render(
                <ConfigProvider locale={ ptBR }>
                    <PageComponent pageContext={ context } params={ context.params } querystring={ querystring } />
                </ConfigProvider>,
                this.RootNode
            );
        };
    }

    setDefaultLayout(Layout: React.ReactNode) {
        this.defaultLayout = Layout;
    }
}
