import React from 'react';
import { Result, Button } from 'antd';
import Route from '../../helpers/Route';

export default function Error404() {
    return (
        <Result
            status="404"
            title="Página não encontrada"
            subTitle="Ops, não encontramos a página que você esta procurando"
            extra={ <Button href={ Route('home') } type="primary">Voltar</Button> }
        />
    );
}


