import api from './api';
import routes from './../routes/routes';
import { encode, decode } from '../helpers/Base64';
import Config from '../helpers/Config';

class AuthService {
    serviceName: string = 'AuthService';
    defaultRedirect: string = '/';
    intentedRoute: null | string = null;
    isUserAuthenticated: boolean = !!this.getUser();

    authenticate(data) {
        return new Promise((resolve, reject) => {
            api.post('/auth/authenticate', data).then((response) => {
                this.isUserAuthenticated = true;
                this.setUser(response.data);
                // EM.notify(`${this.serviceName}.authenticate`, response, this.intentedRoute);
                resolve({
                    response: response,
                    redirect: this.intentedRoute || this.defaultRedirect
                });
                this.intentedRoute = null;
            }).catch((error) => {
                reject(error);
            });
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            api.get('/auth/logout').then((res) => {
                resolve(res);
                this.forgetUser();
            }).catch((error) => {
                reject(error);
            });
        });
    }

    isAuthenticated () {
        return this.isUserAuthenticated;
    }

    setIntentedRoute (route) {
        if (route !== routes.login.name) {
            this.intentedRoute = route || null;
        }
    }

    setUser (user) {
        localStorage.setItem(
            Config('auth.user_index', ''),
            encode(JSON.stringify(user))
        );
        // EM.notify(`${this.serviceName}.user.set`, user);
    }

    getUser () {
        return JSON.parse(
            decode(localStorage.getItem(Config('auth.user_index')))
        );
    }

    forgetUser () {
        this.isUserAuthenticated = false;
        localStorage.removeItem(Config('auth.user_index'));
        // EM.notify(`${this.serviceName}.user.forget`, user);
    }
}

export default new AuthService();

// const AuthService = new Auth();

// export default AuthService;
