import React, { ReactNode } from 'react';
import Password from 'antd/lib/input/Password';
import { StateLinkable } from '../Interfaces';

function InputPassword({ label, link, error, required, suffix, prefix } :
    {
        label: string,
        link: StateLinkable,
        error?: string,
        required?: boolean,
        suffix?: ReactNode,
        prefix?: ReactNode,
    }
) {
    let errorElement: null | JSX.Element = null;
    let itemClass: string = 'ant-row ant-form-item';
    const labelClass = required ? 'ant-form-item-required' : '';
    function onChange(e) {
        // if (typeof this.props.mask === 'function') {
        //     e.target.value = this.props.mask(e.target.value);
        // }
        link.onChange(e);
    }
    if (error) {
        itemClass += ' ant-form-item-has-error';
        errorElement = (
            <div className="ant-form-item-explain">
                <div>{ error }</div>
            </div>
        );
    }

    return (
        <div className={ itemClass }>
            <div className="ant-col ant-form-item-label">
                <label className={ labelClass } title={ label }>{ label }</label>
            </div>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <Password
                            name={ link.name }
                            onChange={ onChange }
                            value={ link.value }
                            suffix={ suffix }
                            prefix={ prefix }
                        />
                    </div>
                </div>
                { errorElement }
            </div>
        </div>
    );
}

InputPassword.displayName = 'InputPassword';

export default InputPassword;
