import Page from 'page';
import routes from './routes';
import './middlewares';
import Router from './Router';
import Views from './../pages';

const DOMNode = document.getElementById('root');
const AppRouter = new Router(DOMNode);

AppRouter.setDefaultLayout(Views.DefaultLayout);

Page(routes.home.name, AppRouter.renderPage(Views.Home));

Page(routes.testimonies.name, AppRouter.renderPage(Views.TestimoniesList));
Page(routes.testimoniesCreate.name, AppRouter.renderPage(Views.TestimoniesCreate));
Page(routes.testimoniesEdit.name, AppRouter.renderPage(Views.TestimoniesEdit));

Page(routes.galleries.name, AppRouter.renderPage(Views.GalleriesList));

Page(routes.login.name, AppRouter.renderPage(Views.Login, null));

// Not found
Page('*', AppRouter.renderPage(Views.Error404, null));

// require('./middlewares');

Page.start({
    click: true,
    hashbang: false
});
