import React, { useRef, useContext } from 'react';
import { DndProvider, useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import Types from 'prop-types';

const ItemType = 'SortableItem';

const SortableContext = React.createContext({
    orientation: 'horizontal',
    onMove: (from, to) => {}
});

export interface SortableItemObject {
    index: number,
    [key: string]: any
};

export function Sort (items: Array<any>, fromIndex: number, toIndex: number) {
    const fromItem = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, fromItem);
    return items;
}

export function SortableItem (props: {[key: string] : any}) {
    const context = useContext(SortableContext);

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemType,
        hover(item: any, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            // @ts-ignore
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            if (!clientOffset) return;

            switch (context.orientation) {
                case 'vertical':
                    // Get vertical middle
                    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                    // Get pixels to the top
                    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                    // Dragging downwards | only move when the cursor is below 50%
                    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                        return;
                    }
                    // Dragging upwards | only move when the cursor is above 50%
                    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                        return;
                    }
                    break;
                case 'horizontal':
                    // Get horizontal middle
                    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
                    // Get pixels to the left
                    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
                    // Dragging rightwards | only move when the cursor is below 50%
                    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                        return;
                    }
                    // Dragging leftwards | only move when the cursor is above 50%
                    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                        return;
                    }
                    break;
            }

            // Perform the action
            context.onMove(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [dragProps, drag] = useDrag({
        item: { type: ItemType, id: props.id, index: props.index },
        collect: (monitor: DragSourceMonitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    });
    drag(drop(ref));
    const className = `sortable-item ${dragProps.isDragging ? 'sortable-item-is-dragging' : ''} ${props.className}`;
    return (
        <props.tag ref={ ref } className={ className }>{ props.children }</props.tag>
    );
}

SortableItem.displayName = 'SortableItem';
SortableItem.defaultProps = {
    tag: 'div',
    className: '',
};
SortableItem.propTypes = {
    id: Types.oneOfType([Types.string, Types.number]).isRequired,
    index: Types.oneOfType([Types.string, Types.number]).isRequired,
};

export function Sortable (
    {tag = 'div', onMove, className = '', orientation = 'horizontal', children }:
    {
        tag: keyof JSX.IntrinsicElements,
        onMove: (from: number, to: number) => void,
        className: string,
        orientation?: 'horizontal' | 'vertical',
        children: React.ReactNode
    }
) {
    const Tag = tag;
    return (
        <DndProvider backend={ HTML5Backend }>
            <Tag className={ `component-sortable ${className}` }>
                <SortableContext.Provider value={ {onMove, orientation} }>
                    { children }
                </SortableContext.Provider>
            </Tag>
        </DndProvider>
    );
}

Sortable.displayName = 'Sortable';

export default Sortable;
