import React from 'react';
import useForm from '../../hooks/useForm';
import { Button } from 'antd';
import InputText from '../../Components/Form/InputText';
import Form from '../../Components/Form/Form';
import TestimonyService, { TestimonyModel } from '../../services/TestimonyService';
const Testimony: TestimonyModel = {};

function TestimoniesForm({ params, afterSave }) {
    const { state, link, getError, onSubmit, loading } = useForm(
        TestimonyService,
        params,
        {afterSave},
        Testimony,
    );

    return (
        <Form onSubmit={ onSubmit.bind(null, state) } loading={ loading }>
            <InputText
                label="Nome"
                link={ link('name') }
                error={ getError('name') }
                required
            />
            <InputText
                label="Conteúdo (Português)"
                link={ link('content') }
                error={ getError('content') }
                required
            />
            <InputText
                label="Conteúdo (Inglês)"
                link={ link('content_en') }
                error={ getError('content_en') }
                required
            />
            <Button htmlType="submit" type="primary">Salvar</Button>
        </Form>
    );
}

export default TestimoniesForm;
