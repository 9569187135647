/**
 * @param path - The path to set the value into the object.
 * @param newValue - The new value.
 * @param obj - The object to receive the new value.
 */
export default function SetFlattened(path, newValue, obj) {
    path = path.split('.');

    var i, laps = path.length - 1, temp = obj;

    for (i = 0; i < laps; i += 1) {
        temp = temp[path[i]];
    }
    temp[path[laps]] = newValue;
    return obj;
}
