import React from 'react';
import { Form as AntForm, Spin } from 'antd';

function Form(
    { loading, children, onSubmit, layout = 'vertical' }:
    {
        loading?: boolean,
        children: any,
        onSubmit?: any,
        layout?: 'vertical' | 'horizontal' | 'inline'
    }
) {
    return (
        <div className="loading-wrapper">
            { loading ? <div className="loading-wrapper-element"><Spin /></div> : null }
            <AntForm onFinish={ onSubmit } layout={ layout }>
                { children }
            </AntForm>
        </div>
    );
}

export default Form;
