import Rest, { Model } from './Rest';

export interface TestimonyModel extends Model{
    id?: string,
    name?: string,
    content?: string,
    content_en?: string,
}

class TestimonyService extends Rest {
    resourceable_as: string = 'App\\Models\\Testimony';
}

export default new TestimonyService('TestimonyService', '/testimonies');
