import React, { useState } from 'react';
import useForm from '../../hooks/useForm';
import Form from '../../Components/Form/Form';
import GalleryService, { GalleryModel } from '../../services/GalleryService';
import ImageUploader from '../../Components/ImageUploader';
import ImageService, { ImageModel } from '../../services/ImageService';
import { Card, Checkbox, Button } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { EditOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import ImagesForm from '../images/Form';
import { AxiosResponse } from 'axios';
import ObjectFind from '../../helpers/ObjectFind';
const Gallery: GalleryModel = {
    images: Array
};

function GalleriesForm({ params }) {
    let modal: React.ReactNode | null = null;
    const [modalItem, setModalItem] = useState<GalleryModel | null>(null);
    const { state, link, changeStateField } = useForm(
        GalleryService,
        params,
        {},
        Gallery,
    );

    if (!state.id) {
        return null;
    }

    function openImageModa(image: ImageModel) {
        setModalItem(image);
    }

    function closeImageModal() {
        setModalItem(null);
    }

    function afterSaveImageModal(res: AxiosResponse) {
        const images = state.images.slice();
        const result = ObjectFind(images, 'id', res.data.id);
        if (result.index > -1) {
            images[result.index] = res.data;
            changeStateField('images', images);
        }
        closeImageModal();
    }

    if (modalItem !== null) {
        modal =  (
            <Modal visible onCancel={ closeImageModal } footer={ null }>
                <ImagesForm afterSave={ afterSaveImageModal } params={ {id: modalItem.id} } />
            </Modal>
        );
    }


    function renderCard(image: ImageModel, imageUrl, onChangeCheckbox, isChecked, i) {
        const description = (
            <small>
                <div><strong>Português:</strong> { image.caption }</div>
                <div><strong>Inglês:</strong> { image.caption_en }</div>
            </small>
        );
        return (
            <Card hoverable
                style={ { cursor: 'default', maxWidth: 210, marginBottom: 20 } }
                bodyStyle={ { padding: '5px 10px 15px' } }
                cover={
                    <img src={ imageUrl } alt="Not found" />
                }
                actions={ [
                    <Checkbox onClick={ onChangeCheckbox } checked={ isChecked } />,
                    <strong>#{ i + 1 }</strong>,
                ] }
            >
                <Meta title={
                    <Button onClick={ openImageModa.bind(null, image) } className="p-0" size="small" type="link">
                        Legendas <EditOutlined />
                    </Button>
                } description={ description } />
            </Card>
        );
    }

    return (
        <Form>
            <ImageUploader
                multiple
                sortable
                label="Imagens"
                service={ ImageService }
                link={ link('images') }
                imageable_id={ state.id }
                imageable_type={ GalleryService.resourceable_as }
                renderCard={ renderCard }
                urlField="urls.explore.url"
            />
            { modal }
        </Form>
    );
}

export default GalleriesForm;
