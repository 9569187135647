import React from 'react';
import useForm from '../../hooks/useForm';
import Form from '../../Components/Form/Form';
import ImageService, { ImageModel } from '../../services/ImageService';
import InputText from '../../Components/Form/InputText';
import { Button } from 'antd';
const Image: ImageModel = {};

function ImagesForm({ params, afterSave }) {
    const { state, link, getError, onSubmit, loading } = useForm(
        ImageService,
        params,
        { afterSave },
        Image,
    );

    return (
        <Form onSubmit={ onSubmit.bind(null, state) } loading={ loading }>
            <InputText
                label="Legenda (Português)"
                link={ link('caption') }
                error={ getError('caption') }
            />
            <InputText
                label="Legenda (Inglês)"
                link={ link('caption_en') }
                error={ getError('caption_en') }
            />
            <Button htmlType="submit" type="primary">Salvar</Button>
        </Form>
    );
}

export default ImagesForm;
