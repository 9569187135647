import React, { Component} from 'react';
import { Layout, Menu, Avatar, Spin } from 'antd';
import Icon, {
  CommentOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import GalleryIcon from './../../Components/Icons/GalleryIcon'
import MenuItem from 'antd/lib/menu/MenuItem';
import Footer from './Footer';
import AuthService from '../../services/AuthService';
import page from 'page';
import EventManager from '../../services/EventManager';
import Route from '../../helpers/Route';
import Config from '../../helpers/Config';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

export default class extends Component<{pageContext: {[key: string]: any}}> {
    unsubscribes: (() => void)[] = [];
    state = {
        gLoading: false,
        collapsed: false,
        collapsedWidth: 80
    };
    componentDidMount() {
        this.unsubscribes.push(
            EventManager.subscribe('gLoading.update', this.listenGLoading)
        );
    }
    componentWillUnmount() {
        this.unsubscribes.map(fn => fn());
    }
    listenGLoading = (loading) => {
        this.setState({ gLoading: loading });
    };
    onToggleSideMenu = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };
    onSiderBreakpoint = (isMobile) => {
        const state = {
            collapsedWidth: 80,
            collapsed: false
        }
        if (isMobile) {
            state.collapsed = true;
            state.collapsedWidth = 0;
        }
        this.setState( state );
    };
    logout = () => {
        this.setState({ gLoading: true }, () => {
            AuthService.logout().then(() => {
                page.redirect(Route('login'));
            });
        });
    };
    renderSidebar = () => {
        const currentResource = this.props.pageContext.pathname.split('/')[1];
        const menuItems = [
            {name: 'Galerias', route: Route('galleries'), icon: <Icon component={ GalleryIcon } />},
            {name: 'Depoimentos', route: Route('testimonies'), icon: <CommentOutlined />},
        ];
        return (
            <Sider
                onBreakpoint={ this.onSiderBreakpoint }
                breakpoint="lg"
                collapsedWidth={ this.state.collapsedWidth }
                collapsible
                collapsed={ this.state.collapsed }
                trigger={ null }>
                <a href={ Route('home') } className="logo">{ Config('app.name') }</a>
                <Menu theme="dark" mode="inline" selectedKeys={ [`/${currentResource}`] }>
                    { menuItems.map((item) => {
                        return (
                            <Menu.Item key={ item.route }>
                                <a href={ item.route }>
                                    { item.icon } <span>{ item.name }</span>
                                </a>
                            </Menu.Item>
                        );
                    }) }
                </Menu>
            </Sider>
        );
    };
    render() {
        const SideMenuTrigger = this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
        return (
            <div className="loading-wrapper">
                { this.state.gLoading ? <div className="loading-wrapper-element"><Spin /></div> : null }
                <Layout style={{ minHeight: '100vh' }}>
                    { this.renderSidebar() }
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }}>
                            <div className="header-menu-wrapper">
                                <div className="header-menu-trigger-wrapper">
                                    <SideMenuTrigger className="header-menu-trigger" onClick={ this.onToggleSideMenu } />
                                </div>
                                <Menu mode="horizontal">
                                    <SubMenu title={ <Avatar icon={ <UserOutlined /> } size="small" /> }>
                                        <MenuItem onClick={ this.logout }>Sair</MenuItem>
                                    </SubMenu>
                                </Menu>
                            </div>
                        </Header>
                        <Content className="main-content">
                            { this.props.children }
                        </Content>
                        <Footer />
                    </Layout>
                </Layout>
            </div>
        );
    }
}
