import React from 'react';
import { Card } from 'antd';
import useList from '../../hooks/useList';
import GalleryService, { GalleryModel } from '../../services/GalleryService';
import GalleriesForm from './Form';

function GalleriesList(props) {
    const {
        collection,
    } = useList(GalleryService, props.querystring, {
        initialQuerystring: {
            $page: 1,
            $sort: 'id',
        }
    });

    return (
        <div>
            { collection.map((gallery: GalleryModel) => {
                return(
                    <div key={ gallery.id }>
                        <Card className="mb-4" title={ gallery.name }>
                            <GalleriesForm params={ {id: gallery.id} } />
                        </Card>
                    </div>
                );
            }) }
        </div>
    );
}

export default GalleriesList;
