import api from "./api";
import EventManager from './EventManager';
import { AxiosResponse, AxiosError } from "axios";

export interface Model {
    [key: string]: any
}

export interface RestInterface {
    find: Function,
    get: Function,
    store: Function,
    update: Function,
    save: Function,
    delete: Function,
    getFormRelations: Function,
    getName: Function
}

export default class Rest implements RestInterface {
    serviceName: string;
    resoruceRoute: string;

    constructor(serviceName: string, resoruceRoute: string) {
        this.serviceName = serviceName;
        this.resoruceRoute = resoruceRoute;
    }

    find (id, qs) {
        return new Promise((resolve, reject) => {
            api.get(`${this.resoruceRoute}/${id}`, {
                params: qs
            })
            .then((response: AxiosResponse) => {
                EventManager.notify(`${this.serviceName}.find`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                EventManager.notify(`${this.serviceName}.find.error`, error);
                reject(error);
            });
        });
    }

    get (qs) {
        return new Promise((resolve, reject) => {
            api.get(this.resoruceRoute, {
                params: qs
            })
            .then((response: AxiosResponse) => {
                EventManager.notify(`${this.serviceName}.get`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                EventManager.notify(`${this.serviceName}.get.error`, error);
                reject(error);
            });
        });
    }

    store (data) {
        return new Promise((resolve, reject) => {
            api.post(this.resoruceRoute, data)
            .then((response: AxiosResponse) => {
                EventManager.notify(`${this.serviceName}.store`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                EventManager.notify(`${this.serviceName}.store.error`, error);
                reject(error);
            });
        });
    }

    update (data) {
        let id;
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
            id = data.get('id');
        } else {
            data._method = 'PUT';
            id = data.id;
        }

        return new Promise((resolve, reject) => {
            api.put(`${this.resoruceRoute}/${id}`, data)
            .then((response: AxiosResponse) => {
                EventManager.notify(`${this.serviceName}.update`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                EventManager.notify(`${this.serviceName}.update.error`, error);
                reject(error);
            });
        });
    }

    save (data) {
        let id = data instanceof FormData ? data.get('id') : data.id;
        if (!id) {
            return this.store(data);
        }
        else {
            return this.update(data);
        }
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            api.delete(`${this.resoruceRoute}/${id}`, {
                data: {_method: 'DELETE'},
            })
            .then((response: AxiosResponse) => {
                EventManager.notify(`${this.serviceName}.delete`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                EventManager.notify(`${this.serviceName}.delete.error`, error);
                reject(error);
            });
        });
    }

    getFormRelations() {
        return new Promise((resolve, reject) => {
            api.get(`${this.resoruceRoute}/get-form-relations`)
            .then((response: AxiosResponse) => {
                EventManager.notify(`${this.serviceName}.getFormRelations`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                EventManager.notify(`${this.serviceName}.getFormRelations.error`, error);
                reject(error);
            });
        });
    }

    getName() {
        return this.serviceName;
    }
}
