export default function GetFlattened(path, obj, defaultValue: any = null) {
    path = path.split('.');
    var i, size = path.length, response = obj;

    for (i = 0; i < size; i += 1) {
        if (response.hasOwnProperty(path[i])) {
            // response = response[path[i]];
            response = response[path[i]] || defaultValue;
        } else {
            return defaultValue;
        }
    }
    return response;
}
