import React, { useEffect, useState } from 'react';
import TestimoniesForm from './Form';
import Redirect from '../../helpers/Redirect';
import Route from '../../helpers/Route';
import { Button, PageHeader } from 'antd';
import EventManager from '../../services/EventManager';
import { TestimonyModel } from '../../services/TestimonyService';
import { AxiosResponse } from 'axios';

function TestimoniesEdit({ params }) {
    const [testimony, setTestimony] = useState<TestimonyModel>({});
    function listenFind(res: AxiosResponse) {
        setTestimony(res.data);
    }
    useEffect(() => {
        const unsubscribe: Function = EventManager.subscribe('Testimonieservice.find', listenFind);
        return () => {
            unsubscribe();
        };
    });

    return (
        <div className="page-content">
            <PageHeader className="p-0 mb-3" title={ testimony.name }
                extra={ <Button href={ Route('testimonies') }>Voltar</Button> }
            />
            <TestimoniesForm params={ params } afterSave={ () => {
                Redirect(Route('testimonies'));
            } } />
        </div>
    );
}

export default TestimoniesEdit;
