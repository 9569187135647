import React from 'react';
import { Table as AntTable } from 'antd';

export default function Table({
    loading,
    columns,
    rowKey,
    collection,
    pagination,
    onChange,
}) {
    return (
        <AntTable className="mt-4" size="small" bordered
            loading={ loading }
            columns={ columns }
            rowKey={ rowKey }
            dataSource={ collection }
            pagination={ pagination }
            onChange={ onChange }
        />
    );
}
