import React from 'react';
import TestimoniesForm from './Form';
import { Button, PageHeader } from 'antd';
import Route from '../../helpers/Route';
import Redirect from '../../helpers/Redirect';

function TestimoniesCreate({ params }) {
    return (
        <div className="page-content">
            <PageHeader className="p-0"
                title="Novo depoimento"
                extra={ <Button href={ Route('testimonies') }>Voltar</Button> }
            />
            <TestimoniesForm params={ params } afterSave={ (res) => {
                Redirect(Route('testimonies'));
            } } />
        </div>
    );
}

export default TestimoniesCreate;
