import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import InputFile from './Form/InputFile';
import { Button, Spin, Card, Checkbox } from 'antd';
import { StateLinkable } from './Interfaces';
import Sortable, { SortableItem, Sort } from './Sortable';
import GetFlattened from '../helpers/GetFlattened';
import { ImageModel } from '../services/ImageService';

interface ImageUploaderProps {
    label: string,
    multiple: boolean,
    sortable?: boolean,
    max: boolean | number,
    maxWidth?: string | number,
    keyField: string,
    orderField: string,
    urlField?: string,
    imageable_type: string,
    imageable_id: string | number,
    service: any,
    renderCard?: Function,
    link: StateLinkable,
}
interface ImageUploaderState {
    loading: boolean,
    showSaveOrderButton: boolean,
    batchBuffer: Array<any>,
    imageBuffer: Array<any>,
    errorsBuffer: Array<any>
}

export class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
    countUploading: number = 0;
    static defaultProps = {
        max: false,
        maxWidth: 160,
        // addButtonText: 'Adicionar imagens',
        // addButtonLoadinText: 'Enviando imagens...',
        sortable: false,
        multiple: false,
        orderField: 'position',
        keyField: 'id',
        urlField: 'urls.admin.url',
    }
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showSaveOrderButton: false,
            batchBuffer: [],
            imageBuffer: [],
            errorsBuffer: []
        };
    }

    getValue = () : Array<any> => {
        return Array.isArray(this.props.link.value)
            ? this.props.link.value.slice()
            : this.props.link.value ? [this.props.link.value] : [];
    }

    onChange = (collection: Array<any>, errors: Array<any>) => {
        const value = Array.isArray(this.props.link.value)
            ? collection
            : collection[0];
        this.props.link.onChange({
            target: { value }
        });
    }

    onUploadEnd = () => {
        if (this.countUploading === 0) {
            let newCollection = (this.state.imageBuffer.length > 0)
                ? this.getValue().concat(this.state.imageBuffer)
                : this.getValue();
            let errorsBuffer = this.state.errorsBuffer.slice();
            this.setState({
                loading: false,
                imageBuffer: [],
                errorsBuffer: [],
            }, () => {
                this.onChange(newCollection, errorsBuffer);
            });
        }
    }

    uploadImage = (image: File, index) => {
        const orderField = this.props.orderField;
        const collection = this.getValue();
        let formData = new FormData();
        formData.append('file', image);
        formData.append(orderField, (collection.length + index));
        formData.append('imageable_id', this.props.imageable_id.toString());
        formData.append('imageable_type', this.props.imageable_type);

        this.props.service.store(formData).then((res) => {
            this.countUploading -= 1;
            let newImageBuffer = this.state.imageBuffer.concat([res.data]);
            this.setState({
                imageBuffer: newImageBuffer
            }, this.onUploadEnd);
        }).catch((err) => {
            this.countUploading -= 1;
            let newErrorsBuffer = this.state.errorsBuffer.concat([{
                err: err,
                file: image
            }]);
            this.setState({
                errorsBuffer: newErrorsBuffer
            }, this.onUploadEnd);
        });
    }

    saveOrder = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        }, () => {
            const newCollection = this.getValue();
            const data = newCollection.map((item, index) => {
                newCollection[index][this.props.orderField] = index;
                return {
                    [this.props.keyField]: item[this.props.keyField],
                    [this.props.orderField]: item[this.props.orderField],
                };
            });
            this.props.service.reorder(data)
            .then(() => {
                this.setState({
                    loading: false,
                    showSaveOrderButton: false
                }, () => {
                    this.onChange(newCollection, []);
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                }, () => {
                    this.onChange(newCollection, [err]);
                });
            });
        });
    }

    batchDelete = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        }, () => {
            const imageIds = this.state.batchBuffer.slice();
            this.props.service.batchDelete({ids: imageIds})
            .then(() => {
                const newCollection = this.getValue().filter((item) => {
                    return imageIds.indexOf(item[this.props.keyField]) === -1;
                });
                this.setState({
                    loading: false,
                    batchBuffer: []
                }, () => {
                    this.onChange(newCollection, []);
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                }, () => {
                    this.onChange(this.getValue(), [err]);
                });
            });
        });
    }

    onAddFiles = (e) => {
        let len = e.target.files.length;
        const collection = this.getValue();
        if (this.props.multiple && typeof this.props.max === 'number') {
            len = this.props.max - collection.length;
        }

        for (let i = 0; i < len; i += 1) {
            let file = e.target.files[i];
            if (file.type.match(/image.*/)) {
                this.uploadImage(file, i);
                this.countUploading += 1;
            }
        }
        e.target.value = null;
        this.setState({
            loading: true
        });
    }

    toggleSelectAll = (e) => {
        e.preventDefault();
        const collection = this.getValue();
        const batchBuffer = collection.length === this.state.batchBuffer.length
            ? []
            : collection.map((item) => item[this.props.keyField]);

        this.setState({
            batchBuffer
        });
    }

    onSortMove = (from: number, to: number) => {
        this.setState({
            showSaveOrderButton: true
        }, () => {
            this.onChange(
                Sort(this.getValue(), from, to),
                []
            );
        });
    }

    onChangeCheckbox(id) {
        let newBatchBuffer = this.state.batchBuffer.slice();
        let result = newBatchBuffer.indexOf(id);
        if (result > -1) {
            newBatchBuffer.splice(result, 1);
        } else {
            newBatchBuffer.push(id);
        }
        this.setState({
            batchBuffer: newBatchBuffer
        });
    }

    renderImageCard = (image: ImageModel, i) => {
        const isChecked = this.state.batchBuffer.indexOf(image[this.props.keyField]) > -1;
        if (typeof this.props.renderCard === 'function') {
            return this.props.renderCard(
                image,
                GetFlattened(this.props.urlField, image),
                this.onChangeCheckbox.bind(this, image[this.props.keyField]),
                isChecked,
                i
            );
        }
        return (
            <Card hoverable
                style={ { maxWidth: this.props.maxWidth } }
                bodyStyle={ { padding: 0 } }
                onClick={ this.onChangeCheckbox.bind(this, image[this.props.keyField]) }
                cover={
                    <img src={ GetFlattened(this.props.urlField, image) } alt="Not found" />
                }
                actions={ [
                    <Checkbox checked={ isChecked } />,
                    <strong>#{ i + 1 }</strong>,
                ] }
            >
            </Card>
        );
    }

    renderImages = () => {
        if (this.state.loading) {
            return <Spin />;
        }

        const collection = this.getValue();
        if (collection.length === 0) {
            return null;
        }

        if (this.props.multiple && this.props.sortable) {
            return (
                <Sortable onMove={ this.onSortMove } className="inline-list" tag="ul">
                    { collection.map((image, i) => {
                        return (
                            <SortableItem className="inline-li" tag="li" key={ image.id } id={ image.id } index={ i }>
                                { this.renderImageCard(image, i) }
                            </SortableItem>
                        );
                    }) }
                </Sortable>
            );
        }

        return (
            <ul className="inline-list">
                { collection.map((image: ImageModel, i) => {
                    return (
                        <li className="inline-li" key={ image.id }>
                            { this.renderImageCard(image, i) }
                        </li>
                    );
                }) }
            </ul>
        );
    }

    renderActionButtons = () => {
        const collection = this.getValue();
    //     let label = this.state.loading ? this.props.addButtonLoadinText : this.props.addButtonText;
        let selectAllToggleButton: React.ReactNode = null;
        let saveOrderButton: React.ReactNode = null;
        let batchDeleteButton: React.ReactNode = null;
        let inputFile: React.ReactNode = null;
        let deleteClassText = 'Excluir imagens selecionadas';

        if (this.props.multiple) {
            if (this.props.max === false || collection.length < this.props.max) {
                inputFile = (
                    <InputFile className="mr-2 mb-3"
                        label={ <span><UploadOutlined /> Enviar</span> }
                        disabled={ this.state.loading }
                        onChange={ this.onAddFiles }
                        multiple={ this.props.multiple }
                    />
                );
            }
            if (this.state.showSaveOrderButton === true) {
                saveOrderButton = (
                    <Button onClick={ this.saveOrder } className="mr-2 mb-3">Salvar ordem das imagens</Button>
                );
            }
            if (collection.length > 0) {
                let selectAllText: string | null = null;
                selectAllText = (collection.length === this.state.batchBuffer.length)
                    ? 'Desmarcar tudo'
                    : 'Marcar tudo';
                selectAllToggleButton = (
                    <Button className="mr-2 mb-3" onClick={ this.toggleSelectAll }>{ selectAllText }</Button>
                );
            }
        } else {
            deleteClassText = 'Excluir imagem';
            if (collection.length === 0) {
                inputFile = (
                    <InputFile className="mr-2 mb-3"
                        label={ <span><UploadOutlined /> Enviar</span> }
                        disabled={ this.state.loading }
                        onChange={ this.onAddFiles }
                    />
                );
            }
        }

        if (this.state.batchBuffer.length > 0) {
            batchDeleteButton = (
                <Button className="mr-2 mb-3" onClick={ this.batchDelete } type="dashed" danger>{ deleteClassText }</Button>
            );
        }

        return (
            <>
                { inputFile }
                { selectAllToggleButton }
                { batchDeleteButton }
                { saveOrderButton }
            </>
        );
    }

    render() {
        return (
            <div className="ant-row ant-form-item">
                <div className="ant-col ant-form-item-label">
                    <label title={ this.props.label }>{ this.props.label }</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                            { this.renderActionButtons() }
                            <div>
                                { this.renderImages() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageUploader;
