import routes from './../routes/routes';

export default function Route(route, params?){
    if (routes[route].name === undefined) {
        return null;
    }

    if (typeof params !== 'object') {
        return routes[route].name;
    }

    let r = routes[route].name;
    for (let key in params) {
        r = r.replace(`:${key}`, params[key]);
    }
    return r;
};
