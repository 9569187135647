import React from 'react';
import { Layout } from 'antd';
import rtsign from './../../img/rtdigital-sign.svg'

export default function Footer() {
    return (
        <Layout.Footer>
            <div className="text-center">
                <a href="https://rtdigital.com.br" target="_blank" rel="noreferrer noopener">
                    <img src={ rtsign } width="74" alt="#RT Digital" />
                </a>
            </div>
        </Layout.Footer>
    );
}

