import qs from 'qs';
import { useState, useEffect } from 'react';
import AlertService from '../services/AlertService';
import Redirect from '../helpers/Redirect';
import { RestInterface } from '../services/Rest';
import { AxiosError } from 'axios';
import { SorterResult, TableAction } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';

let searchTimeoutId: number | undefined;

function useList(
    Service: RestInterface,
    propsQuerystring,
    options = {
        initialQuerystring: {
            $page: 1,
            $sort: '-id',
        }
    }
) {
    const [queryState, setQueryState]  = useState({
        ...options.initialQuerystring,
        ...propsQuerystring
    });
    // const queryState = Object.assign({
    //     page: 1,
    //     sort: '-id',
    // }, propsQuerystring);
    // let sortField = propsQuerystring.sort || '';
    // let sortDirection = '';
    // if (sortField[0] === '-') {
    //     sortField = sortField.slice(1);
    //     sortDirection = '-';
    // }

    const [loading, setLoading]  = useState(false);
    // const [isPopoverOpen, setPopoverVisibility]  = useState(false);
    const [deletingItem, setDeleteItem]  = useState<any>(false);
    // const [tableQuery, setTableQuery]  = useState({
    //     page: {
    //         index: parseInt(propsQuerystring.page || 1, 10) - 1,
    //         size: parseInt(propsQuerystring.offset || 15, 10),
    //     },
    //     sort: {
    //         field: sortField,
    //         direction: sortDirection === '-' ? 'desc' : 'asc'
    //     },
    //     qfield: {
    //         field: 'name',
    //         fieldName: 'Nome'
    //     },
    //     q: propsQuerystring.q
    // });
    const [collection, setCollection]  = useState([]);
    const [pagination, setPagination]  = useState({
        current: 1,
        pageSize: 15,
        total: 0,
        position: ['topLeft', 'bottomLeft'],
        showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`
        }
    });

    function fetchData(query) {
        setLoading(true);
        Service.get(query)
        .then(({ data }) => {
            setCollection(data.data);
            setPagination({
                current: data.current_page,
                pageSize: parseInt(data.per_page, 10),
                total: data.total,
                position: ['topLeft', 'bottomLeft'],
                showTotal: pagination.showTotal
            });
            setLoading(false);
        })
        .catch((err) => {
            console.error(err);
            setLoading(false);
        });
    }

    function onTableChange(
        pagination: PaginationProps,
        filters: Object,
        sorter: SorterResult<{}>,
        { action }: { action: TableAction }
    ) {
        const query = {
            ...queryState,
            $page: pagination.current,
            $offset: pagination.pageSize,
        };
        switch (action) {
            case 'sort':
                if (sorter.column) {
                    query.$sort = (sorter.order === 'ascend' ? '' : '-') + sorter.field;
                } else {
                    query.$sort = '-id';
                }
                break;
            case 'filter':
                console.log(filters);
                break;
        }

        setQueryState(query);
        fetchData(query);
        Redirect(`${window.location.pathname}?${qs.stringify(query, {encode: false})}`);
    }

    // function onTableChange(query) {
    //     // setTableQuery(Object.assign({}, tableQuery, query));
    //     // const nQueryState = Object.assign({}, queryState, {
    //     //     page: parseInt(query.page.index, 10) + 1,
    //     //     offset: parseInt(query.page.size, 10),
    //     //     sort: `${query.sort.direction === 'asc' ? '' : '-'}${query.sort.field}`,
    //     // });
    //     // Redirect(`${window.location.pathname}?${qs.stringify(nQueryState)}`);
    // }

    // function onChangeSearchField(field, fieldName) {
    //     let query = Object.assign({}, tableQuery, {
    //         page: {
    //             index: 0,
    //             size: tableQuery.page.size,
    //         },
    //         qfield: {
    //             field,
    //             fieldName,
    //         }
    //     });
    //     setTableQuery(query);
    //     setPopoverVisibility(false);
    //     const nQueryState = Object.assign({}, queryState, {
    //         qfield: field,
    //     });
    //     Redirect(`${window.location.pathname}?${qs.stringify(nQueryState)}`);
    // }

    // function onSearch(e) {
        // window.clearTimeout(searchTimeoutId);
        // setLoading(true);
        // const q = e.target.value;
        // let query = Object.assign({}, tableQuery, {
        //     page: {
        //         index: 0,
        //         size: tableQuery.page.size,
        //     },
        //     q
        // });
        // setTableQuery(query);
        // searchTimeoutId = window.setTimeout(() => {
        //     const nQueryState = Object.assign({}, queryState, {
        //         q,
        //     });
        //     Redirect(`${window.location.pathname}?${qs.stringify(nQueryState)}`);
        // }, 320);
    // }

    function onSearch(e) {
        window.clearTimeout(searchTimeoutId);
        setLoading(true);
        // const q = e.target.value;
        const query = {
            ...queryState,
            $page: 1,
            $q: e.target.value,
        };
        // let query = Object.assign({}, tableQuery, {
        //     page: {
        //         index: 0,
        //         size: tableQuery.page.size,
        //     },
        //     q
        // });
        // setTableQuery(query);
        setQueryState(query);
        searchTimeoutId = window.setTimeout(() => {
            // const nQueryState = Object.assign({}, queryState, {
            //     q,
            // });
            fetchData(query);
            Redirect(`${window.location.pathname}?${qs.stringify(query, {encode: false})}`);
        }, 320);
    }

    // function onTogglePopover(e) {
    //     setPopoverVisibility(!isPopoverOpen);
    // }

    function asTokDeleteResource(item) {
        setDeleteItem(item || false);
    }

    function closeModal() {
        setDeleteItem(false);
    }

    function confirmDeleteResource() {
        setLoading(true);
        Service.delete(deletingItem.id)
        .then(() => {
            AlertService.success('Registro removido com sucesso');
            setDeleteItem(false);
            fetchData(queryState);
        })
        .catch((error: AxiosError) => {
            if (error.response && error.response.status !== 403) {
                AlertService.error('Ops, não foi possivel excluir este registro.');
            }
            setDeleteItem(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        fetchData(queryState);
        return () => {
            window.clearTimeout(searchTimeoutId);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsQuerystring]);

    return {
        onTableChange,
        // onChangeSearchField,
        // onTogglePopover,
        onSearch,
        setDeleteItem,
        asTokDeleteResource,
        confirmDeleteResource,
        closeModal,
        // tableQuery,
        // isPopoverOpen,
        deletingItem,
        collection,
        pagination,
        loading,
        queryState,
    };
}

export default useList;
