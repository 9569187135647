export default {
    home: {name: '/', title: 'Dashboard'},

    testimonies: {name: '/depoimentos', title: 'Depoimentos'},
    testimoniesCreate: {name: '/depoimentos/criar', title: 'Depoimentos » Novo'},
    testimoniesEdit: {name: '/depoimentos/:id/alterar', title: 'Depoimentos » Alterar'},

    galleries: {name: '/galerias', title: 'Galerias'},

    passwordRecovery: {name: '/autenticacao/recuperar-senha', title: 'Recuperar senha'},
    passwordReset: {name: '/autenticacao/resetar-senha', title: 'Resetar senha'},
    login: {name: '/autenticacao/login', title: 'Login'},
};
