import Home from "./Home";
import DefaultLayout from "./layout/Default";
import TestimoniesList from "./testimonies/List";
import TestimoniesCreate from "./testimonies/Create";
import TestimoniesEdit from "./testimonies/Edit";
import GalleriesList from "./galleries/List";
import Login from "./auth/Login";
import Error404 from "./layout/Error404";

const Views = {
    DefaultLayout,
    Home,
    TestimoniesList, TestimoniesCreate, TestimoniesEdit,
    GalleriesList,
    Login,
    Error404,
};
export default Views;
