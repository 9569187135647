import app from './../configs/app';
import auth from './../configs/auth';

const configs = {
    app,
    auth,
};

export default function Config(path: string, defaultValue: any = null){
    let pathSearch = path.split('.');
    let depth = pathSearch.length;
    let value = Object.assign({}, configs);
    for (let i = 0; i < depth; i += 1) {
        if (value.hasOwnProperty(pathSearch[i])) {
            value = value[pathSearch[i]];
        } else {
            return defaultValue;
        }
    }
    return value;
};
