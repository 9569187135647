import Rest, { Model } from "./Rest";
import api from './api';

export interface ImageModel extends Model{
    id?: string,
    caption?: string,
    caption_en?: string,
    filename?: string,
    position?: number,
    imageable_id?: number,
    imageable_type?: string,
}

class ImageService extends Rest {
    IMAGEABLE_TYPE: Object = {};
    reorder(data) {
        data._method = 'PUT';
        return new Promise((resolve, reject) => {
            api.put(`${this.resoruceRoute}/reorder`, data)
            .then((response) => {
                // EM.notify(`${this.serviceName}.reorder`, response);
                resolve(response);
            })
            .catch((error) => {
                // EM.notify(`${this.serviceName}.reorder.error`, error);
                reject(error);
            });
        });
    }

    batchDelete(data) {
        data._method = 'DELETE';
        return new Promise((resolve, reject) => {
            api.delete(`${this.resoruceRoute}/batch-delete`, {
                data,
            })
            .then((response) => {
                // EM.notify(`${this.serviceName}.batchDelete`, response);
                resolve(response);
            })
            .catch((error) => {
                // EM.notify(`${this.serviceName}.batchDelete.error`, error);
                reject(error);
            });
        });
    }
}

export default new ImageService('ImageService', '/images');
