import Axios from 'axios';
import Config from '../helpers/Config';

const api = Axios.create({
    withCredentials: true,
    baseURL: `${Config('app.api')}`,
    headers: {
        'X-Requested-With': 'XMlHttpRequest'
    }
});

export default api;
