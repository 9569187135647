// import App from 'app';
import Page from 'page';
import api from '../services/api';
import AuthService from '../services/AuthService';
import routes from './routes';
import Route from '../helpers/Route';
import AlertService from '../services/AlertService';
import { AxiosResponse, AxiosError } from 'axios';

// const AlertProvider = App.provider('Alert');

const allowedRoutes = [
    routes.login.name,
    routes.passwordRecovery.name,
    routes.passwordReset.name,
];

Page((context, next) => {
    if (AuthService.isAuthenticated() || allowedRoutes.indexOf(context.pathname) > -1) {
        next();
    } else {
        AuthService.setIntentedRoute(context.path);
        Page.redirect(Route('login'));
    }
});

// api.interceptors.request.use((config) => {
//     let token = AuthService.getToken();
//     // console.log(token);
//     if (token) {
//         config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

api.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, (error: AxiosError) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
            case 400:
            case 401:
                // AuthService.clearCache();
                AuthService.setIntentedRoute(window.location.href.replace(window.location.origin, ''));
                Page.redirect(Route('login'));
                break;
            case 403:
                AlertService.error('Ops, parece que você não tem permissão para esta ação. 😕');
                break;
            default:
                break;
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMlHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error);
    }
    // console.log(error.config);
    return Promise.reject(error);
});
